<template>
  <!--  SCB App, SCB Easy Net, SCB SMS  -->
  <div>
    <b-overlay :show="isTogging">
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            SCB Easy App
            <AutoBankingStatus :status="!!scbAccount" />
            <br>
            <small class="text-muted">(รองรับฝากและถอน)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="scbAccount"
              id="scbAccountStatus"
              v-model="scbAccount.isEnabled"
              name="scbAccountStatus"
              :disabled="isTogging"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!scbAccount"
              @click="$bvModal.show('scb-easy-app-modal')"
          />
          <ScbEasyAppModal :agent-bank-account-id="bankAccountForm.id" :scb-account="scbAccount" />
        </div>
      </div>
      <hr />

      <!--    -->
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            SCB Easy Net
            <AutoBankingStatus :status="!!scbEasyNetAccount" />
            <br>
            <small class="text-muted">(รองรับฝากเท่านั้น)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="scbEasyNetAccount"
              id="scbEasyNetStatus"
              v-model="scbEasyNetAccount.isEnabled"
              name="scbEasyNetStatus"
              :disabled="isTogging"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!scbEasyNetAccount"
              @click="$bvModal.show('scb-easy-net-modal')"
          />
          <ScbEasyNetModal :agent-bank-account-id="bankAccountForm.id" :scb-easy-net-account="scbEasyNetAccount" />
        </div>
      </div>
      <hr />

      <!--    -->
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            SMS
            <AutoBankingStatus :status="!!bankingSmsReceiver" />
            <br>
            <small class="text-muted">(รองรับฝากเท่านั้น)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="bankingSmsReceiver"
              id="bankingSmsReceiverStatus"
              v-model="bankingSmsReceiver.isEnabled"
              name="bankingSmsReceiverStatus"
              :disabled="isTogging"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!bankingSmsReceiver"
              @click="$bvModal.show('sms-receiver-modal')"
          />
          <SmsReceiverModal :agent-bank-account-id="bankAccountForm.id" :banking-sms-receiver="bankingSmsReceiver" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoScbForm',
  components: {
    ScbEasyAppModal: () => import('./easy-app-modal'),
    ScbEasyNetModal: () => import('./easy-net-modal'),
    SmsReceiverModal: () => import('../auto-banking-sms-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        scbAccount: null,
        scbEasyBetAccount: null,
        bankingSmsReceiver: null,
      })
    }
  },
  data() {
    return {
      scbAccount: null,
      scbEasyNetAccount: null,
      bankingSmsReceiver: null,
      isTogging: false,
    }
  },
  watch: {
    'scbAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('scb')
      }
    },
    'scbEasyNetAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('scb-easy-net')
      }
    },
    'bankingSmsReceiver.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('sms')
      }
    },
  },
  created() {
    this.scbAccount = this.bankAccountForm.scbAccount
    this.scbEasyNetAccount = this.bankAccountForm.scbEasyNetAccount
    this.bankingSmsReceiver = this.bankAccountForm.bankingSmsReceiver
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>